import DragScroll from "./dragscroll";

export function initDnD(view) {
	const ctrl = webix.copy(DragControl);

	ctrl.view = view;
	ctrl.master = this;

	ctrl.Local = this.app.getService("local");

	webix.DragControl.addDrag(view.$view, ctrl);
	webix.DragControl.addDrop(view.$view, ctrl, true);
}

export function switchCursor(e, view) {
	if (webix.DragControl.active) return;

	const id = webix.html.locate(e, "webix_l_id");
	if (id && view.getItem(id).type == "task") {
		const node = view.getItemNode(id);
		const mode = getMoveMode(node, e.clientX);
		node.style.cursor = mode == "move" ? "pointer" : "ew-resize";
	}
}

function getMoveMode(node, x) {
	const rect = node.getBoundingClientRect();
	const p = (x - rect.left) / rect.width;
	const minWidth = webix.env.touch ? 400 : 200;
	const delta = 0.2 / (rect.width > minWidth ? rect.width / minWidth : 1);

	if (p < delta) return "start";
	if (p > 1 - delta) return "end";
	return "move";
}

function updateDragScrollConfig(ds) {
	const scales = this.Local.getScales();
	ds.senseX = Math.round(scales.cellWidth * (webix.env.touch ? 1 : 0.5));
	ds.senseY = Math.round(scales.cellHeight * (webix.env.touch ? 3 : 1));
	return ds;
}

function isProgressDrag(t, node) {
	while (t != node) {
		if (t.classList.contains("webix_gantt_progress_drag")) return true;
		t = t.parentNode;
	}
	return false;
}

const DragControl = {
	$longTouchLimit: true,
	$dragCreate: function(t, e, pointer) {
		if (this.master.State.readonly) return false;

		const ctx = this.getContext();
		ctx.$touch = pointer === "touch";

		const id = this.locateEvent(e);
		if (id) {
			const item = this.view.getItem(id);
			if (item.$group) return false;

			const target = e.target;

			if (target.classList.contains("webix_gantt_link")) {
				ctx.mode = "links";
				return Modes[ctx.mode].$dragCreate.call(this, t, e, id);
			}

			const node = this.view.getItemNode(id);
			if (!ctx.$touch && isProgressDrag(target, node)) {
				ctx.mode = "progress";
				return Modes[ctx.mode].$dragCreate.call(this, t, e, id);
			}

			const scroll = this.view.getScrollState();
			const evContext = this.getEventContext(e, ctx);

			let mode = getMoveMode(node, evContext.x);
			if (item.type != "task" && (mode == "start" || mode == "end"))
				mode = "move";

			const scales = this.Local.getScales();
			let step = scales.cellWidth;
			if (scales.precise) {
				const nsc = this.master.app
					.getService("helpers")
					.getSmallerCount(scales.minUnit);
				step = step / (typeof nsc === "number" ? nsc : nsc());
			}

			webix.extend(
				ctx,
				{
					id,
					mode,
					node,
					step,
					dx: 0,
					from: this.view,
					snode: t.querySelector(".webix_scroll_cont"),
					x: evContext.x,
					scroll: scroll,
					t: parseInt(node.style.top),
					l: parseInt(node.style.left),
					w: parseInt(node.style.width),
				},
				true
			);

			if (!this.master.app.callEvent("bars:beforedrag", [item, ctx]))
				return false;

			if (Modes[ctx.mode].dragScroll)
				webix.extend(
					ctx,
					updateDragScrollConfig.call(this, Modes[ctx.mode].dragScroll)
				);

			const html = node.cloneNode(true);
			html.className += " webix_drag_zone webix_gantt_mode_" + mode;

			node.style.visibility = "hidden";
			webix.html.addCss(t, "webix_gantt_in_action", true);

			t.style.cursor = mode == "move" ? "move" : "ew-resize";
			t.appendChild(html);
			return html;
		} else if (
			this.master.app.config.split &&
			this.master.State.display !== "resources" &&
			e.target == t
		) {
			ctx.mode = "split";
			return Modes[ctx.mode].$dragCreate.call(this, t, e);
		}

		return false;
	},
	$dragDestroy: function(t, node) {
		const ctx = this.getContext();

		if (Modes[ctx.mode].dragScroll) DragScroll.reset(ctx);

		if (ctx.mode && Modes[ctx.mode].$dragDestroy)
			return Modes[ctx.mode].$dragDestroy.call(this, t, node);

		if (ctx.$waitUpdate) {
			ctx.$waitUpdate.catch(() => {
				this.master.Action({ action: "update-task-time", id: ctx.id });
			});
		} else
			ctx.$waitUpdate = this.master.Action({
				action: "update-task-time",
				id: ctx.id,
			});

		ctx.$waitUpdate.finally(() => {
			ctx.node.style.visibility = "";
			webix.html.remove(node);

			if (this.master.app.config.split && ctx.id)
				this.master.Action({
					action: "split-resize",
					id: ctx.id,
				});
		});

		t.style.cursor = "";
		webix.html.removeCss(t, "webix_gantt_in_action");
		return false;
	},
	$dragIn: function(s, t, e) {
		const ctx = this.getContext();
		if (!ctx.mode) return false;

		if (Modes[ctx.mode].dragScroll) {
			DragScroll.reset(ctx);
			DragScroll.start(ctx, e);
		}

		if (Modes[ctx.mode].$dragIn)
			return Modes[ctx.mode].$dragIn.call(this, s, t, e);
		return true;
	},
	$dragPos: function(pos) {
		const ctx = this.getContext();
		return Modes[ctx.mode].$dragPos.call(this, pos);
	},
	$dragOut: function(s, t, n, e) {
		const ctx = this.getContext();
		if (Modes[ctx.mode].$dragOut)
			return Modes[ctx.mode].$dragOut.call(this, s, t, n, e);
		return null;
	},
	$drop: function(s, t, e) {
		const ctx = this.getContext();
		if (!ctx.mode) return false;

		if (Modes[ctx.mode].$drop) return Modes[ctx.mode].$drop.call(this, s, t, e);

		const { id, mode, dx, step } = ctx;
		const time = Math.round(dx / step);
		ctx.timeShift = time;
		if (
			!this.master.app.callEvent("bars:beforedrop", [
				this.view.getItem(ctx.id),
				ctx,
			])
		)
			return false;

		ctx["$waitUpdate"] = this.master.Action({
			action: "update-task-time",
			mode,
			time,
			id,
		});
	},
	locateEvent: function(ev, context) {
		if (context) {
			ev = document.elementFromPoint(context.x, context.y);
		}
		return webix.html.locate(ev, "webix_l_id");
	},
	getEventContext: function(e, ctx) {
		if (ctx.$touch) {
			if (e.changedTouches && !(e.touches && e.touches[0])) {
				const t = e.changedTouches[0];
				return { x: t.pageX, y: t.pageY };
			}
			return e.time ? e : webix.env.touch.context(e);
		}
		return { x: e.clientX, y: e.clientY };
	},
	getContext: function() {
		return webix.DragControl.getContext();
	},
	setDragOffset: function(node, target, e) {
		const ctx = this.getContext();
		const pos = webix.html.pos(e);
		const offset = webix.html.offset(node);

		ctx.width = offset.width;
		ctx.height = offset.height;
		ctx.x_offset = offset.x - pos.x;
		ctx.y_offset = offset.y - pos.y;
	},
};

const links = {
	dragScroll: {
		direction: "xy",
	},
	$dragCreate: function(t, e, id) {
		const ctx = this.getContext();
		const link = e.target;
		const node = this.view.getItemNode(id);
		const scroll = this.view.getScrollState();
		const offset = webix.html.offset(link);
		const css = link.classList;

		webix.extend(
			ctx,
			{
				id,
				from: this.view,
				node,
				link,
				fromStart: css.contains("webix_gantt_link_left"),
				start: {
					x: offset.x + offset.width / 2 + scroll.x,
					y: offset.y + offset.height / 2 + scroll.y,
				},
			},
			true
		);

		if (
			!this.master.app.callEvent("bars:beforedrag", [
				this.view.getItem(id),
				ctx,
			])
		)
			return false;

		if (Modes[ctx.mode].dragScroll)
			webix.extend(
				ctx,
				updateDragScrollConfig.call(this, Modes[ctx.mode].dragScroll)
			);

		link.style.opacity = 1;
		webix.html.addCss(node, "webix_gantt_task_in_action", true);

		const html = webix.html.create("div", { visibility: "hidden" });
		document.body.appendChild(html);
		return html;
	},
	$dragDestroy: function(t, node) {
		const ctx = this.getContext();

		this.master.Action({ action: "temp-link" });

		if (ctx.target) {
			const node = this.view.getItemNode(ctx.target);
			webix.html.removeCss(node, "webix_gantt_link_visible");
		}

		ctx.link.style.opacity = "";
		webix.html.removeCss(ctx.node, "webix_gantt_task_in_action");
		webix.html.remove(node);

		return false;
	},
	$dragIn: function(s, t, e) {
		const ctx = this.getContext();
		const evContext = this.getEventContext(e, ctx);
		const id = this.locateEvent(e, ctx.$touch ? evContext : null);

		if (!id) return false;

		const node = this.view.getItemNode(id);
		if (id != ctx.target) {
			webix.html.addCss(node, "webix_gantt_link_visible", true);
			ctx.target = id;
		}
		return node;
	},
	$dragPos: function(pos) {
		const ctx = this.getContext();
		const nscroll = this.view.getScrollState();

		ctx.end = {
			x: pos.x + nscroll.x,
			y: pos.y + nscroll.y,
		};

		this.master.Action({ action: "temp-link", start: ctx.start, end: ctx.end });
	},
	$dragOut: function(s, t, n, e) {
		const ctx = this.getContext();
		const evContext = this.getEventContext(e, ctx);
		const id = this.locateEvent(e, ctx.$touch ? evContext : null);

		if (ctx.target && id != ctx.target) {
			const node = this.view.getItemNode(ctx.target);
			webix.html.removeCss(node, "webix_gantt_link_visible");
			ctx.target = null;
		}
		return null;
	},
	$drop: function(s, t, e) {
		const ctx = this.getContext();
		const source = ctx.id;
		const evContext = this.getEventContext(e, ctx);
		const target = this.locateEvent(e, ctx.$touch ? evContext : null);

		if (!target || source == target) return false;

		const node = this.view.getItemNode(target);
		const rect = node.getBoundingClientRect();
		const toStart = evContext.x - rect.left < rect.width / 2;

		const type = (ctx.linkType = (ctx.fromStart ? 1 : 0) + (toStart ? 0 : 2));
		ctx.targetId = target;
		if (
			this.master.app.callEvent("bars:beforedrop", [
				this.view.getItem(source),
				ctx,
			])
		)
			this.master.Action({ action: "add-link", source, target, type });
	},
};

const resize = {
	$dragPos: function(pos) {
		const ctx = this.getContext();
		const nscroll = this.view.getScrollState();
		const node = webix.DragControl.getNode();
		const { mode, l, t, w, x, id, scroll, step } = ctx;
		const dx = (ctx.dx = pos.x - x - scroll.x + nscroll.x);

		if (mode === "start") {
			pos.x = Math.min(l + dx, l + w - step);
			node.style.width = `${Math.max(w - dx, step)}px`;
		} else if (mode === "end") {
			pos.x = l;
			node.style.width = `${Math.max(w + dx, step)}px`;
		}
		pos.y = t;

		this.master.Action({
			action: "drag-task",
			id,
			left: pos.x,
			width: parseInt(node.style.width),
		});

		if (this.master.app.config.split)
			this.master.Action({
				action: "split-resize",
				id,
			});
	},
};

const move = {
	dragScroll: {
		direction: "x",
	},
	$dragPos: function(pos) {
		const ctx = this.getContext();
		const { node, l, t, x, id, scroll } = ctx;
		const nscroll = this.view.getScrollState();
		const dx = (ctx.dx = pos.x - x - scroll.x + nscroll.x);

		pos.x = l + dx;
		pos.y = t;

		this.master.Action({
			action: "drag-task",
			id,
			left: pos.x,
			width: parseInt(node.style.width),
		});

		if (this.master.app.config.split)
			this.master.Action({
				action: "split-resize",
				id,
			});
	},
};

const progress = {
	$dragCreate: function(t, e, id) {
		const ctx = this.getContext();
		const node = this.view.getItemNode(id);
		const prevProgress = this.view.getItem(id).progress;
		const progressNode = node.querySelector(".webix_gantt_progress");
		const evContext = this.getEventContext(e, ctx);
		const scroll = this.view.getScrollState();

		webix.extend(
			ctx,
			{
				id,
				node,
				prevProgress,
				progressNode,
				from: this.view,
				x: evContext.x,
				w: parseInt(node.style.width),
				scroll,
			},
			true
		);

		if (
			!this.master.app.callEvent("bars:beforedrag", [
				this.view.getItem(id),
				ctx,
			])
		)
			return false;

		t.style.cursor = "ew-resize";
		webix.html.addCss(t, "webix_gantt_in_action", true);
		webix.html.addCss(node, "webix_gantt_mode_progress", true);

		const html = webix.html.create("div", { visibility: "hidden" });
		document.body.appendChild(html);
		return html;
	},
	$dragPos: function(pos) {
		const ctx = this.getContext();
		const { x, scroll, w, node } = ctx;
		const nscroll = this.view.getScrollState();

		const dx = pos.x - x - scroll.x + nscroll.x;
		const progress = Math.min(
			Math.max(ctx.prevProgress + Math.round((dx * 100) / w), 0),
			100
		);

		if (ctx.progress != progress) {
			ctx.progress = progress;
			Modes[ctx.mode].updateTaskTemplate.call(this, ctx, progress);
		}

		const colors = { contrast: "#393939", dark: "#20262B" };
		node.style.zIndex = "3";
		node.style.boxShadow = `${
			colors[webix.skin.$name || "#ffffff"]
		} -1px 0px 0px`;
	},
	$drop: function() {
		const ctx = this.getContext();
		const { id, progress, prevProgress } = ctx;

		if (
			!webix.isUndefined(progress) &&
			progress != prevProgress &&
			this.master.app.callEvent("bars:beforedrop", [this.view.getItem(id), ctx])
		)
			ctx["$waitUpdate"] = this.master.Action({
				action: "update-task-progress",
				progress,
				id,
			});
	},
	$dragDestroy: function(t, node) {
		const ctx = this.getContext();

		if (ctx.$waitUpdate) {
			ctx.$waitUpdate.catch(() => {
				this.master.Action({ action: "update-task-progress", id: ctx.id });
			});
		} else
			ctx.$waitUpdate = this.master.Action({
				action: "update-task-progress",
				id: ctx.id,
			});

		t.style.cursor = "";
		webix.html.removeCss(t, "webix_gantt_in_action");
		webix.html.removeCss(ctx.node, "webix_gantt_mode_progress");

		webix.html.remove(node);
		return false;
	},
	updateTaskTemplate(ctx, progress) {
		const node = ctx.progressNode;
		node.style.width = progress + "%";
		node.innerHTML = this.master.DragProgressTemplate(progress);
	},
};

const split = {
	$dragCreate: function(t, e) {
		const selection = webix.html.create("DIV", {
			class: "webix_gantt_split_selection",
		});

		const scales = this.Local.getScales();
		const scroll = this.view.getScrollState();

		const tb = e.target.getBoundingClientRect();
		const index = Math.floor((e.y - tb.y + scroll.y) / scales.cellHeight);
		const offsetX = e.x - tb.x;

		selection.style.top = index * scales.cellHeight + 6 + "px";
		selection.style.left = offsetX + scroll.x + "px";
		selection.style.height = scales.cellHeight - 14 + "px";
		selection.style.width = "0px";

		let step = scales.cellWidth;
		if (scales.precise) {
			const nsc = this.master.app
				.getService("helpers")
				.getSmallerCount(scales.minUnit);
			step = Math.round(step / (typeof nsc === "number" ? nsc : nsc()));
		}

		const ctx = this.getContext();
		webix.extend(
			ctx,
			{
				from: this.view,
				node: selection,
				start: { offsetX, clientX: e.clientX },
				index,
				step,
				scroll,
			},
			true
		);

		t.appendChild(selection);

		const html = webix.html.create("div", { visibility: "hidden" });
		document.body.appendChild(html);
		return html;
	},
	$dragPos(pos) {
		const ctx = this.getContext();
		const width = (ctx.dx = pos.x - ctx.start.clientX);
		if (pos.x < ctx.start.clientX) {
			ctx.node.style.left = ctx.start.offsetX + ctx.scroll.x + width + "px";
			ctx.node.style.width = -width + "px";
		} else {
			ctx.node.style.left = ctx.start.offsetX + ctx.scroll.x + "px";
			ctx.node.style.width = pos.x - ctx.start.clientX + "px";
		}
	},
	$drop() {
		const ctx = this.getContext();
		const id = this.view.getIdByIndex(ctx.index);

		let start = Math.round((ctx.start.offsetX + ctx.scroll.x) / ctx.step);
		let end = Math.round(ctx.dx / ctx.step);
		if (end < 0) {
			start = start + end;
			end *= -1;
		}

		this.master.Action({ action: "add-task", id, dates: { start, end } });
	},
	$dragDestroy(t, node) {
		const ctx = this.getContext();

		webix.html.remove(node);
		webix.html.remove(ctx.node);

		return false;
	},
};

const Modes = {
	links,
	move,
	progress,
	start: resize,
	end: resize,
	split,
};
