import {
	addUnit,
	getDiff,
	getUnitStart,
	grid,
	newLink,
	resetScales,
	smallerCount,
	updateLink,
	updateTask,
	updateTaskDuration,
} from "@xbs/webix-gantt-lib";

export default class Helpers {
	constructor(app) {
		this.app = app;
		this.defaultResourceUnit = "hour";
		this.defaultResourceValues = {
			hour: 8,
		};
		this.resourceValueRanges = {
			hour: [0.5, 24],
		};
		this.resourceValueSteps = {
			hour: 0.5,
		};
		this.unitLoadNorm = {
			day: 8,
			week: 40,
			month: 170,
			quarter: 510,
			year: 2040,
		};
	}

	/**
	 * Get name initials
	 * @param {string} name - resource name
	 * @returns {string} name initials
	 */
	initials(name) {
		let initials = name.match(/\b\w/g) || [];
		if (initials.length == 1)
			return name.charAt(0).toUpperCase() + name.charAt(1);
		return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
	}

	/**
	 * Get an additional css class for resource avatar
	 * @param {string} name - a resource name
	 * @returns {string} a css class
	 */
	avatarCss(name) {
		return (
			" webix_gantt_person_avatar_" +
			((name.charCodeAt(1) + (name.length % 10)) % 10)
		);
	}

	/**
	 * A template for an assignment avatar
	 * @param {object} resource - a resource object
	 * @param {string} cssClass - an addition css class for the avatar
	 * @param {boolean} withTooltip - specifies whether  an avatar should get tooltip
	 * @returns {string} html string for assignment avatar
	 */
	resourceAvatar(resource, cssClass, withTooltip) {
		let css = "webix_gantt_person_avatar";
		if (typeof cssClass == "string") css += " " + cssClass;
		if (resource.avatar)
			return `<img class="${css}" src="${resource.avatar}" webix_tooltip="${
				withTooltip ? resource.name : ""
			}"/>`;
		const txt = resource.name;
		return `<div class='${css} ${
			txt ? this.avatarCss(txt) : ""
		}' webix_tooltip="${withTooltip ? resource.name : ""}">${
			txt ? this.initials(txt) : ""
		}</div>`;
	}

	/**
	 * Get the default value for the resource unit
	 * @param {object} obj - a resource object
	 * @returns {any} the default resource
	 */
	getDefaultResourceValue(obj) {
		const unit = this.getResourceUnit(obj);
		return this.defaultResourceValues[unit];
	}

	/**
	 * Get a resource unit
	 * @param {object} obj - a resource object
	 * @returns {string} a resource unit
	 */
	getResourceUnit(obj) {
		return obj.unit || this.defaultResourceUnit;
	}
	/**
	 * Get a resource value range
	 * @param {object} obj - a resource object
	 * @returns {array} a resource value range
	 */
	getResourceValueRange(obj) {
		const unit = this.getResourceUnit(obj);
		return this.resourceValueRanges[unit] || [1, 100];
	}
	/**
	 * Get a resource value step
	 * @param {object} obj - a resource object
	 * @returns {array} a resource value step
	 */
	getResourceValueStep(obj) {
		const unit = this.getResourceUnit(obj);
		return this.resourceValueSteps[unit] || null;
	}
	/**
	 * Check if a date is a holiday for an assigned resource
	 * @param {date} date - Date object
	 * @param {object} cal - a calendar object of a resource
	 * @returns {boolean} true if date is a holiday
	 */
	isResourceHoliday(date, cal) {
		let result = false;
		if (cal.holidays)
			result = cal.holidays.find(d => d.getTime() == date.getTime());
		if (cal.weekDays)
			result = result || cal.weekDays.indexOf(date.getDay()) == -1;
		return result;
	}

	/* wrappers for gantt-lib functions */

	addUnit(unit, date, inc) {
		return addUnit(unit)(date, inc);
	}

	getDifference(unit, next, prev) {
		return getDiff(unit)(next, prev, webix.Date.startOnMonday);
	}

	getUnitStart(unit, date) {
		return getUnitStart(unit, date, webix.Date.startOnMonday);
	}

	updateTask(task, index) {
		const local = this.app.getService("local");
		return updateTask(task, index, local.getScales(), local.getTaskHeight());
	}

	setScales(start, end, precise, width, height, scales) {
		return resetScales(
			start,
			end,
			precise,
			width,
			height,
			scales,
			this.app.config.isHoliday,
			webix.Date.startOnMonday
		);
	}

	updateLink(link, start, end) {
		const local = this.app.getService("local");
		return updateLink(link, start, end, local.getTaskHeight());
	}

	updateTaskDuration(task, scales, mode, dir) {
		return updateTaskDuration(task, scales, mode, dir);
	}

	getSmallerUnit(unit) {
		return smallerCount[unit][0];
	}

	getSmallerCount(unit) {
		return smallerCount[unit][1];
	}

	newLink(box, start, end) {
		return newLink(box, start, end);
	}

	drawGridInner(width, height, color) {
		return grid(width, height, color);
	}
}
